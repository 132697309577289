/* eslint-disable max-len */
// Auto generated file. Do not edit.

export const voyages_filter: Record<string, TVoyages_filter> = {
  'en-AU': {
    voyages: {
      filter: {
        showFeaturedDestinations: 'Show featured destinations',
        showAllDestinations: 'Show all destinations',
        minDuration: 'Min duration',
        maxDuration: 'Max duration',
        maxPricePerPerson: 'Max price (per person)',
        showOnlyOffers: 'Show only offers',
        shipCapacity: 'Ship capacity',
        shipCapacityDescription: 'All our ships are small enough to dock at local ports and get intimate  with nature. Please select preferred number of passengers:',
        maxPrice: 'Max price',
        travelDates: 'Travel dates',
        numberOfDays: 'Number of days',
        whenDoYouWantToTravel: 'When do you want to travel?',
        whenDoYouWantToTravelDesc: 'Choose when you would like to travel or leave empty to see all of our departures.',
        travelAnytime: 'Travel "anytime"',
        howManyNights: 'How many nights?',
        clearSelection: 'Clear selection',
        startDate: 'Start Date',
        endDate: 'End Date'
      }
    }
  },
  'en-GB': {
    voyages: {
      filter: {
        showFeaturedDestinations: 'Show featured destinations',
        showAllDestinations: 'Show all destinations',
        minDuration: 'Min duration',
        maxDuration: 'Max duration',
        maxPricePerPerson: 'Max price (per person)',
        showOnlyOffers: 'Show only offers',
        shipCapacity: 'Ship capacity',
        shipCapacityDescription: 'All our ships are small enough to dock at local ports and get intimate  with nature. Please select preferred number of passengers:',
        maxPrice: 'Max price',
        travelDates: 'Travel dates',
        numberOfDays: 'Number of days',
        whenDoYouWantToTravel: 'When do you want to travel?',
        whenDoYouWantToTravelDesc: 'Choose when you would like to travel or leave empty to see all of our departures.',
        travelAnytime: 'Travel "anytime"',
        howManyNights: 'How many nights?',
        clearSelection: 'Clear selection',
        startDate: 'Start Date',
        endDate: 'End Date'
      }
    }
  },
  'en-US': {
    voyages: {
      filter: {
        showFeaturedDestinations: 'Show featured destinations',
        showAllDestinations: 'Show all destinations',
        minDuration: 'Min duration',
        maxDuration: 'Max duration',
        maxPricePerPerson: 'Max price (per person)',
        showOnlyOffers: 'Show only offers',
        shipCapacity: 'Ship capacity',
        shipCapacityDescription: 'All our ships are small enough to dock at local ports and get intimate  with nature. Please select preferred number of passengers:',
        maxPrice: 'Max price',
        travelDates: 'Travel dates',
        numberOfDays: 'Number of days',
        whenDoYouWantToTravel: 'When do you want to travel?',
        whenDoYouWantToTravelDesc: 'Choose when you would like to travel or leave empty to see all of our departures.',
        travelAnytime: 'Travel "anytime"',
        howManyNights: 'How many nights?',
        clearSelection: 'Clear selection',
        startDate: 'Start Date',
        endDate: 'End Date'
      }
    }
  },
  'de-DE': {
    voyages: {
      filter: {
        showFeaturedDestinations: 'Enthaltene Reiseziele anzeigen',
        showAllDestinations: 'Alle Reiseziele anzeigen',
        minDuration: 'Minimale Dauer',
        maxDuration: 'Maximale Dauer',
        maxPricePerPerson: 'Maximaler Preis (pro Person)',
        showOnlyOffers: 'Nur Angebote anzeigen',
        shipCapacity: 'Schiffskapazität',
        shipCapacityDescription: 'Alle unsere Schiffe sind klein genug, um in lokalen Häfen anzulegen und die Natur hautnah zu erleben. Bitte wählen Sie die gewünschte Anzahl von Passagieren:',
        maxPrice: 'Höchster Preis',
        travelDates: 'Reisedaten',
        numberOfDays: 'Anzahl der Tage',
        whenDoYouWantToTravel: 'Wann möchten Sie reisen? ',
        whenDoYouWantToTravelDesc: 'Wählen Sie aus, wann Sie reisen möchten, oder lassen Sie das Feld leer, um alle unsere Reisetermine zu sehen.',
        travelAnytime: 'Reisen Sie „zu jeder Zeit“',
        howManyNights: 'Wie viele Nächte?',
        clearSelection: 'Auswahl entfernen',
        startDate: 'Früheste Hinreise',
        endDate: 'Späteste Rückreise'
      }
    }
  },
  'gsw-CH': {
    voyages: {
      filter: {
        showFeaturedDestinations: 'Enthaltene Reiseziele anzeigen',
        showAllDestinations: 'Alle Reiseziele anzeigen',
        minDuration: 'Minimale Dauer',
        maxDuration: 'Maximale Dauer',
        maxPricePerPerson: 'Maximaler Preis (pro Person)',
        showOnlyOffers: 'Nur Angebote anzeigen',
        shipCapacity: 'Schiffskapazität',
        shipCapacityDescription: 'Alle unsere Schiffe sind klein genug, um in lokalen Häfen anzulegen und die Natur hautnah zu erleben. Bitte wählen Sie die gewünschte Anzahl von Passagieren:',
        maxPrice: 'Höchster Preis',
        travelDates: 'Reisedaten',
        numberOfDays: 'Anzahl der Tage',
        whenDoYouWantToTravel: 'Wann möchten Sie reisen? ',
        whenDoYouWantToTravelDesc: 'Wählen Sie aus, wann Sie reisen möchten, oder lassen Sie das Feld leer, um alle unsere Reisetermine zu sehen.',
        travelAnytime: 'Reisen Sie „zu jeder Zeit“',
        howManyNights: 'Wie viele Nächte?',
        clearSelection: 'Auswahl entfernen',
        startDate: 'Früheste Hinreise',
        endDate: 'Späteste Rückreise'
      }
    }
  },
  'fr-FR': {
    voyages: {
      filter: {
        showFeaturedDestinations: 'Voir les destinations présentées',
        showAllDestinations: 'Voir toutes les destinations',
        minDuration: 'Durée min.',
        maxDuration: 'Durée max.',
        maxPricePerPerson: 'Prix max. (par personne)',
        showOnlyOffers: 'Afficher uniquement les offres',
        shipCapacity: 'Capacité du navire',
        shipCapacityDescription: 'Nos navires à taille humaine permettent de se rapprocher au plus près de la nature. Veuillez sélectionner la capacité de votre choix :',
        maxPrice: 'Prix max.',
        travelDates: 'Dates du voyage',
        numberOfDays: 'Nombre de jours',
        whenDoYouWantToTravel: 'Quand souhaitez-vous voyager ?',
        whenDoYouWantToTravelDesc: 'Choisissez la période à laquelle vous souhaitez voyager ou laissez le champ vide pour voir toutes nos dates de départ.',
        travelAnytime: 'Voyager « n’importe quand »',
        howManyNights: 'Nombre de jours',
        clearSelection: 'Effacer la sélection',
        startDate: 'Date de début',
        endDate: 'Date de fin'
      }
    }
  },
  'da-DK': {
    voyages: {
      filter: {
        showFeaturedDestinations: 'Vis udvalgte destinationer',
        showAllDestinations: 'Vis alle destinationer',
        minDuration: 'Min. varighed',
        maxDuration: 'Maks. varighed',
        maxPricePerPerson: 'Maks. pris (pr. person)',
        showOnlyOffers: 'Vis kun tilbud',
        shipCapacity: 'Skibskapacitet',
        shipCapacityDescription: 'Alle vores skibe er små nok til at lægge til i lokale havne og komme tæt på naturen. Vælg det ønskede antal passagerer:',
        maxPrice: 'Maks. pris',
        travelDates: 'Rejsedatoer',
        numberOfDays: 'Antal dage',
        whenDoYouWantToTravel: 'Hvornår vil du gerne rejse?',
        whenDoYouWantToTravelDesc: 'Vælg, hvornår du vil rejse, eller lad feltet stå tomt for at se alle vores afrejsedatoer.',
        travelAnytime: 'Rejs "når som helst"',
        howManyNights: 'Hvor mange dage?',
        clearSelection: 'Ryd valg',
        startDate: 'Startdato',
        endDate: 'Slutdato'
      }
    }
  },
  'sv-SE': {
    voyages: {
      filter: {
        showFeaturedDestinations: 'Visa utvalda destinationer',
        showAllDestinations: 'Visa alla destinationer',
        minDuration: 'Minimilängd',
        maxDuration: 'Maxlängd',
        maxPricePerPerson: 'Maxpris (per person)',
        showOnlyOffers: 'Visa endast erbjudanden',
        shipCapacity: 'Fartygets kapacitet',
        shipCapacityDescription: 'Alla våra fartyg är små nog att kunna lägga till i mindre hamnar och komma nära naturen. Välj önskat antal passagerare:',
        maxPrice: 'Maxpris',
        travelDates: 'Resedatum',
        numberOfDays: 'Antal dagar',
        whenDoYouWantToTravel: 'När vill du resa?',
        whenDoYouWantToTravelDesc: 'Välj när nu vill resa eller lämna detta tomt för att se alla avgångar.',
        travelAnytime: 'Res ”när som helst”',
        howManyNights: 'Hur många dagar?',
        clearSelection: 'Rensa val',
        startDate: 'Startdatum',
        endDate: 'Slutdatum'
      }
    }
  },
  'nb-NO': {
    voyages: {
      filter: {
        showFeaturedDestinations: 'Vis fremhevede destinasjoner',
        showAllDestinations: 'Vis alle destinasjoner',
        minDuration: 'Minimum varighet',
        maxDuration: 'Maksimal varighet',
        maxPricePerPerson: 'Makspris (per person)',
        showOnlyOffers: 'Bare vis tilbud',
        shipCapacity: 'Skipets kapasitet',
        shipCapacityDescription: 'Alle skipene våre er små nok til å legge til havn i lokale havner og til å komme nært på naturen. Velg ønsket antall passasjerer:',
        maxPrice: 'Makspris',
        travelDates: 'Reisedatoer',
        numberOfDays: 'Antall dager',
        whenDoYouWantToTravel: 'Når vil du reise?',
        whenDoYouWantToTravelDesc: 'Velg når du vil reise eller la feltet stå tomt for å se alle avreiser.',
        travelAnytime: 'Reis «når som helst»',
        howManyNights: 'Hvor mange dager?',
        clearSelection: 'Fjern valg',
        startDate: 'Startdato',
        endDate: 'Sluttdato'
      }
    }
  },
  'en': {
    voyages: {
      filter: {
        showFeaturedDestinations: 'Show featured destinations',
        showAllDestinations: 'Show all destinations',
        minDuration: 'Min duration',
        maxDuration: 'Max duration',
        maxPricePerPerson: 'Max price (per person)',
        showOnlyOffers: 'Show only offers',
        shipCapacity: 'Ship capacity',
        shipCapacityDescription: 'All our ships are small enough to dock at local ports and get intimate  with nature. Please select preferred number of passengers:',
        maxPrice: 'Max price',
        travelDates: 'Travel dates',
        numberOfDays: 'Number of days',
        whenDoYouWantToTravel: 'When do you want to travel?',
        whenDoYouWantToTravelDesc: 'Choose when you would like to travel or leave empty to see all of our departures.',
        travelAnytime: 'Travel "anytime"',
        howManyNights: 'How many nights?',
        clearSelection: 'Clear selection',
        startDate: 'Start Date',
        endDate: 'End Date'
      }
    }
  }
}

export type TVoyages_filter = {
  voyages: {
    filter: {
      showFeaturedDestinations: string;
      showAllDestinations: string;
      minDuration: string;
      maxDuration: string;
      maxPricePerPerson: string;
      showOnlyOffers: string;
      shipCapacity: string;
      shipCapacityDescription: string;
      maxPrice: string;
      travelDates: string;
      numberOfDays: string;
      whenDoYouWantToTravel: string;
      whenDoYouWantToTravelDesc: string;
      travelAnytime: string;
      howManyNights: string;
      clearSelection: string;
      startDate: string;
      endDate: string;
    };
  };
}
