import { DateRange } from '@src/types/expeditionPlanner';

export type TSimpleDate = { year: number; month: number; unixTime: number };

export const simpleDateComparator = (a: TSimpleDate, b: TSimpleDate) => {
  if (a.year < b.year || (a.year === b.year && a.month < b.month)) {
    return -1;
  }
  if (a.year > b.year || (a.year === b.year && a.month > b.month)) {
    return 1;
  }

  return 0;
};

export const shiftDateToLastDayOfMonth = (time: number | null) => {
  if (time === null) {
    return null;
  }
  const d = new Date(time);
  return new Date(d.getFullYear(), d.getMonth() + 1, 0).getTime();
};

export const shiftDateToFirstDayOfMonth = (time: number | null) => {
  if (time === null) {
    return null;
  }
  const d = new Date(time);
  return new Date(d.getFullYear(), d.getMonth(), 1).getTime();
};

export const unixTimeToSimpleDate = (
  time: number | null
): TSimpleDate | null => {
  if (time === null) {
    return null;
  }

  const d = new Date(time);
  return {
    year: d.getFullYear(),
    month: d.getMonth(),
    unixTime: time
  };
};

export const DateToSimpleDate = (date: Date | null): TSimpleDate | null => {
  if (!date) {
    return null;
  }

  const d = new Date(date.getTime());
  return {
    year: d.getFullYear(),
    month: d.getMonth(),
    unixTime: d.getTime()
  };
};

export const fillReturnDateIfEmpty = (
  filterState: TVoyagesFilterState,
  changeState?: (filterState: TVoyagesFilterState) => void
) => {
  // clone localFilterState so we don't inadvertantly mutate state
  const finalFilterState = { ...filterState };
  // if we do not have an returnDate, use the existing departuredate
  if (finalFilterState.departureDate && !finalFilterState.returnDate) {
    finalFilterState.returnDate = shiftDateToLastDayOfMonth(
      finalFilterState.departureDate
    );
    changeState?.(finalFilterState);
  }
  return finalFilterState;
};

export const isBetweenTwoSimpleDates = (
  from: TSimpleDate,
  to: TSimpleDate,
  date: TSimpleDate
) => {
  const isBeforeStartDate =
    (date.month < from.month && date.year === from.year) ||
    date.year < from.year;
  const isAfterEndDate =
    (date.month > to.month - 1 && date.year === to.year) || date.year > to.year;
  if (isBeforeStartDate || isAfterEndDate) {
    return false;
  }

  return true;
};

export const selectedDataRangeText = (
  date: DateRange | null,
  locale: TLocale
): string => {
  if (!date) {
    return '';
  }
  const startDate = date.start
    ? new Date(date.start).toLocaleDateString(locale, {
        year: 'numeric',
        month: 'short'
      })
    : '';

  const endDate = date.end
    ? new Date(date.end).toLocaleDateString(locale, {
        year: 'numeric',
        month: 'short'
      })
    : '';
  return `${startDate} - ${endDate}`;
};

export const addMissingDates = (dateRange: DateRange | null) => {
  if (!dateRange) {
    return null;
  }
  /* Assuming here that there will always be a dateRange.start */
  return {
    start: dateRange.start,
    end:
      dateRange.end ??
      new Date(dateRange.start.getFullYear(), dateRange.start.getMonth() + 1, 0)
  };
};
