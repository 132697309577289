import { useEffect, useState } from 'react';
import clsx from 'clsx';

import { Icon, IconButton } from '@atoms';
import {
  ArrowLeftSLine,
  ArrowRightSLine,
  CloseFill
} from '@components/icons/System';
import { useLocale, usePrevious, useTranslate } from '@hooks';
import { clamp, mapLocaleToContenfulFormat } from '@utils';
import {
  DateToSimpleDate,
  isBetweenTwoSimpleDates,
  TSimpleDate
} from '@utils/departureFilter';
import { voyages_filter } from 'microcopies';
import { DateRange } from '@src/types/expeditionPlanner';

type DateFilterType = 'startDate' | 'endDate';

type TDateFilterProps = {
  filterState: DateRange | null;
  onChange: (newFilterState: DateRange | null) => void;
  latestYearWithDeparture?: number;
  setDisableDoneButton?: (boolValue: boolean) => void;
};

const DateFilter = ({
  filterState,
  onChange,
  latestYearWithDeparture,
  setDisableDoneButton
}: TDateFilterProps) => {
  const actualCurrentMonth = new Date().getMonth();
  const actualCurrentYear = new Date().getFullYear();
  const [editStartDate, setEditStartDate] = useState(false);
  const [editEndDate, setEditEndDate] = useState(false);
  const [currentYear, setCurrentYear] = useState(
    filterState?.start
      ? filterState.start.getFullYear()
      : new Date().getFullYear()
  );

  const filterTranslate = useTranslate(voyages_filter, (x) => x.voyages.filter);

  const [hoverDate, setHoverDate] = useState<null | TSimpleDate>(null);

  const [startDate, setStartDate] = useState<null | TSimpleDate>(
    DateToSimpleDate(filterState?.start ?? null)
  );

  const [endDate, setEndDate] = useState<null | TSimpleDate>(
    DateToSimpleDate(filterState?.end ?? null)
  );
  const previousEndDate = usePrevious(endDate);
  const previousStartDate = usePrevious(startDate);

  const locale = useLocale();
  const contentfulLocale = mapLocaleToContenfulFormat(locale);

  useEffect(() => {
    setDisableDoneButton?.(!startDate);
  }, [startDate, setDisableDoneButton]);

  useEffect(() => {
    setStartDate(DateToSimpleDate(filterState?.start ?? null));
    setEndDate(DateToSimpleDate(filterState?.end ?? null));
    if (!filterState?.start) {
      setEditStartDate(true);
    }
    if (filterState?.start && !filterState?.end) {
      setEditEndDate(true);
    }
    if (filterState?.start && filterState?.end) {
      setEditStartDate(false);
      setEditEndDate(false);
    }
  }, [filterState]);

  const minDate = new Date();
  const maxDate = new Date(latestYearWithDeparture ?? currentYear + 10, 0, 1);

  const updateParentComponent = (month: number, type: DateFilterType) => {
    const newDate = new Date(currentYear, month, 1);
    const newSimpleDate = {
      year: newDate.getFullYear(),
      month: newDate.getMonth(),
      unixTime: newDate.getTime()
    };
    const newStartDate = type === 'startDate' ? newSimpleDate : startDate;
    let newEndDate;
    if (endDate || type === 'endDate') {
      newEndDate = type === 'endDate' ? newSimpleDate : endDate;
    }

    if (!newStartDate?.year) {
      return;
    }

    const newFilterDate: DateRange = {
      start: new Date(newStartDate.year, newStartDate.month, 1)
    };
    if (newEndDate?.year) {
      newFilterDate.end = new Date(newEndDate.year, newEndDate.month + 1, 0);
    }

    onChange(newFilterDate);
  };

  useEffect(() => {
    /* if we delete an endDate/startDate we want to force update of the
    parent just in case the user closes the component. Parent logic then will
    kick in and add the enddate to the same as the startdate */
    if (previousEndDate?.year && !endDate && startDate) {
      const newFilterDate: DateRange = {
        start: new Date(startDate.year, startDate.month, 1)
      };

      onChange(newFilterDate);
    }

    /* If we delete the startDate, we want to set the startDate to the same 
    month as endDate */
    if (previousEndDate?.year && endDate && !startDate) {
      const lastDayOfMonth = new Date(
        endDate.year,
        endDate.month + 1,
        0
      ).getDate();

      const newFilterDate: DateRange = {
        start: new Date(endDate.year, endDate.month, 1),
        end: new Date(endDate.year, endDate.month, lastDayOfMonth)
      };

      onChange(newFilterDate);
    }

    if (previousStartDate?.year && !startDate && !endDate) {
      onChange(null);
    }
  }, [endDate, startDate]);

  /* Determine when hovering whether the month we are hovering over is
  allowed to be selected depending on what we are currently editing */
  const shouldHover = (year: number, monthIndex: number) => {
    /* Any months in the calendar that are past should automatically be false */
    if (
      (monthIndex < actualCurrentMonth && year === actualCurrentYear) ||
      year < actualCurrentYear
    ) {
      return false;
    }
    /* If editing the enddate then we need to be after the start date */
    if (editEndDate && startDate) {
      return (
        year > startDate.year ||
        (year === startDate.year && monthIndex >= startDate.month)
      );
    }
    /* If editing the startdate then we need to be before the end date */
    if (editStartDate && endDate) {
      return (
        year < endDate.year ||
        (year === endDate.year && monthIndex <= endDate.month)
      );
    }
    return true;
  };

  /* Can override two sets of chosen dates by clicking dates */
  const canOverride = (
    year: number | undefined,
    monthIndex: number | undefined,
    type: DateFilterType
  ): boolean => {
    if (!startDate || !endDate || !year || monthIndex === undefined) {
      return false;
    }
    /* If we seeing if can override the end date, either the year
    has to be greater than the current start date year OR the month has to be
    greater than the start date month (obviously also not being the actual
      current start date month) */
    if (
      (year > startDate.year ||
        (year === startDate.year &&
          monthIndex > startDate.month &&
          monthIndex !== endDate.month)) &&
      type === 'endDate'
    ) {
      return true;
    }
    /* If we are seeing if the start date can be overridden the same logic
    as above is made except year and month checks are BEFORE the current
    start date */
    if (
      (year < startDate.year ||
        (year === startDate.year &&
          monthIndex < startDate.month &&
          monthIndex !== startDate.month)) &&
      type === 'startDate'
    ) {
      return true;
    }
    return false;
  };

  /* When clicking on a month value this function actually sets
  values */
  const setMonthInterval = (month: number) => {
    const date = new Date(currentYear, month, 1);
    if (
      (editStartDate && shouldHover(currentYear, month)) ||
      canOverride(currentYear, month, 'startDate')
    ) {
      setStartDate({
        year: date.getFullYear(),
        month: date.getMonth(),
        unixTime: date.getTime()
      });
      setEditStartDate(false);
      if (!endDate) {
        setEditEndDate(true);
      }
      updateParentComponent(month, 'startDate');
    }
    if (
      (editEndDate && shouldHover(currentYear, month)) ||
      canOverride(currentYear, month, 'endDate')
    ) {
      setEndDate({
        year: date.getFullYear(),
        month: date.getMonth(),
        unixTime: date.getTime()
      });
      setEditEndDate(false);
      updateParentComponent(month, 'endDate');
    }
  };

  const isBetweenStartAndEndMonths = (monthIndex: number, year: number) => {
    let endDateTime = endDate;
    let startDateTime = startDate;
    if (editEndDate) {
      endDateTime = hoverDate;
    }
    if (editStartDate) {
      startDateTime = hoverDate;
    }

    if (!startDateTime || !endDateTime) {
      return false;
    }

    const compareDate = DateToSimpleDate(new Date(year, monthIndex, 1));
    if (compareDate) {
      return isBetweenTwoSimpleDates(startDateTime, endDateTime, compareDate);
    }
    return false;
  };

  const isStartDate = (monthIndex: number) =>
    monthIndex === startDate?.month && currentYear === startDate?.year;

  const isEndDate = (monthIndex: number) =>
    monthIndex === endDate?.month && currentYear === endDate?.year;

  const isStartOrEndDate = (monthIndex: number) =>
    isStartDate(monthIndex) || isEndDate(monthIndex);

  const pointerHoverLogic = (date: Date) => {
    if (shouldHover(date.getFullYear(), date.getMonth())) {
      setHoverDate({
        year: date.getFullYear(),
        month: date.getMonth(),
        unixTime: date.getTime()
      });
    }
  };

  const monthElements = [];
  const dummyDate = new Date(2000, 0, 1);
  const currentDate = new Date();
  for (let monthIndex = 0; monthIndex < 12; monthIndex += 1) {
    dummyDate.setMonth(monthIndex);
    const month = dummyDate.toLocaleString(contentfulLocale, {
      month: 'short'
    });
    const isDisabled =
      monthIndex < currentDate.getMonth() &&
      currentYear <= currentDate.getFullYear();
    monthElements.push(
      <li
        key={month}
        className={clsx('flex flex-1 mb-6 disabled:bg-transparent ', {
          '!bg-primary-hx-indigo text-white': isStartOrEndDate(monthIndex),
          'bg-indigo-light-300':
            !isStartOrEndDate(monthIndex) &&
            isBetweenStartAndEndMonths(monthIndex, currentYear)
        })}
      >
        <button
          disabled={isDisabled}
          onPointerEnter={() => {
            const date = new Date(currentYear, monthIndex, 1);
            pointerHoverLogic(date);
          }}
          onPointerLeave={() => {
            setHoverDate(null);
          }}
          className={clsx(
            'flex flex-1 justify-center px-3 py-3 ui-text capitalize disabled:bg-transparent',
            {
              'hover:bg-indigo-light-200': shouldHover(currentYear, monthIndex),
              '!bg-primary-hx-indigo text-white': isStartOrEndDate(monthIndex),
              'bg-indigo-light-200':
                !isStartOrEndDate(monthIndex) &&
                isBetweenStartAndEndMonths(monthIndex, currentYear),
              'text-warm-gray-4': isDisabled
            }
          )}
          onClick={() => setMonthInterval(monthIndex)}
          type="button"
        >
          {month}
        </button>
      </li>
    );
    if ((monthIndex + 1) % 4) {
      monthElements.push(
        <div
          onPointerEnter={() => {
            const date = new Date(currentYear, monthIndex, 1);
            pointerHoverLogic(date);
          }}
          onPointerLeave={() => {
            setHoverDate(null);
          }}
          key={`${month}${currentYear}-spacer`}
          id={`${month}${currentYear}-spacer`}
          className={clsx('mb-6', {
            'bg-indigo-light-200': isBetweenStartAndEndMonths(
              monthIndex,
              currentYear
            )
          })}
        />
      );
    }
  }

  const returnMonthString = (unixTimeStamp: number | null | undefined) => {
    if (!unixTimeStamp) {
      return '';
    }
    const date = new Date(unixTimeStamp);
    return date.toLocaleString(contentfulLocale, {
      month: 'short'
    });
  };

  /* Either show a "chosen" date either for the start or end
  or the base text */
  const returnDateButton = (
    dateVar: TSimpleDate | null,
    type: DateFilterType,
    baseText: string
  ) => {
    const isEditing = type === 'startDate' ? editStartDate : editEndDate;
    if (!dateVar) {
      return (
        <p
          className={clsx('text-md font-medium leading-5 my-1', {
            'text-primary-hx-indigo': isEditing,
            'text-gray-300': !isEditing
          })}
        >
          {baseText}
        </p>
      );
    }
    return (
      <button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (type === 'startDate') {
            setStartDate(null);
            setEditEndDate(false);
            setEditStartDate(true);
          } else {
            setEndDate(null);
            setEditEndDate(startDate !== null);
            setEditStartDate(startDate === null);
          }
          setHoverDate(null);
        }}
        className="flex items-center"
      >
        <p
          className={clsx('text-md font-medium leading-5', {
            'text-primary-hx-indigo': isEditing
          })}
        >{`${returnMonthString(dateVar?.unixTime)} ${dateVar.year}`}</p>
        <Icon
          fill={clsx({
            'text-primary-hx-indigo': isEditing
          })}
          size="2x"
          icon={CloseFill}
        />
      </button>
    );
  };

  return (
    <fieldset className="flex flex-col items-center gap-2">
      <div className={clsx('flex items-center justify-between w-full')}>
        {returnDateButton(
          startDate,
          'startDate',
          filterTranslate((x) => x.startDate)
        )}
        {returnDateButton(
          endDate,
          'endDate',
          filterTranslate((x) => x.endDate)
        )}
      </div>
      <div className="flex items-center justify-between w-full p-2">
        <IconButton
          isDisabled={currentYear === currentDate.getFullYear()}
          buttonColor="gray"
          isRounded={true}
          onClick={() =>
            setCurrentYear(
              clamp(
                currentYear - 1,
                minDate.getFullYear(),
                maxDate.getFullYear()
              )
            )
          }
          aria-label="Select previous year"
          icon={ArrowLeftSLine}
        />
        <p className="text-lg font-medium leading-5">{currentYear}</p>
        <IconButton
          buttonColor="gray"
          isRounded={true}
          onClick={() =>
            setCurrentYear(
              clamp(
                currentYear + 1,
                minDate.getFullYear(),
                maxDate.getFullYear()
              )
            )
          }
          aria-label="Select next year"
          icon={ArrowRightSLine}
        />
      </div>

      <ul className="grid w-full grid-cols-7-minmax-content">
        {monthElements}
      </ul>
    </fieldset>
  );
};

export default DateFilter;
