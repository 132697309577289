import { TTranslator } from '@hooks/useTranslate';
import { TAppbar } from '@microcopies/sets/appbar';

/* Instead of making an api call to get raw phone numbers, use the existing
microset value for appbar.talktous and remove any surrounding text */
export const getPhoneNumber = (
  translate: TTranslator<TAppbar['appbar']>
): string => {
  const phoneNumber = translate((x) => x.talkToUs);
  return phoneNumber.replace(/[^0-9 ()+-]/g, '').trim();
};
