export const listSummary = ({
  list,
  maxCharCount,
  emptyText,
  moreText
}: {
  list: string[];
  maxCharCount: number;
  emptyText: string;
  moreText: string;
}) => {
  // If the list is empty, return the empty string text
  if (list.length === 0) {
    return emptyText;
  }

  // Add first item
  let summary = list[0];
  let subtractCount = 1;

  // Add the second item if there is enough room
  if (list.length > 1 && summary.length < maxCharCount - 4) {
    summary += `, ${list[1]}`;
    subtractCount += 1;
  }

  // Truncate if necessary
  if (summary.length > maxCharCount) {
    summary = `${summary.substring(0, maxCharCount - 3)}...`;
  }

  // Add +x more if there are more items
  if (list.length > subtractCount) {
    summary += ` +${list.length - subtractCount} ${moreText}`;
  }

  return summary;
};

listSummary({
  emptyText: 'empty',
  moreText: 'more',
  list: [],
  maxCharCount: 25
});
